.index-form {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 20px;
  margin: 20px;
  border: 1px solid #fff;
  border-radius: 10px;
  color: #fff;
  background-color: #424242;
}

.index-form label {
  margin-bottom: 10px;
}

.index-form input[type="text"],
.index-form textarea[type="text"],
.index-form input[type="file"] {
  margin-left: 10px;
}

.index-form input[type="file"] {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
}

.index-form ul {
  list-style-type: none;
  padding: 0;
}

.index-form ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.index-form input[type="submit"] {
  margin-top: 20px;
  cursor: pointer;
  width: fit-content;
  align-self: center;
}
