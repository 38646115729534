@font-face {
  font-family: "Aeonik";
  src: url("Aeonik-Regular.otf") format("opentype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Aeonik";
  src: url("Aeonik-RegularItalic.otf") format("opentype");
  font-style: italic;
  font-weight: normal;
}
@font-face {
  font-family: "Aeonik";
  src: url("Aeonik-Bold.otf") format("opentype");
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: "Aeonik";
  src: url("Aeonik-BoldItalic.otf") format("opentype");
  font-style: italic;
  font-weight: bold;
}

.App {
  width: 100%;
  display: flex;
  min-width: 250px;
  /* font-family: 'Aeonik', sans-serif;
  font-weight: 500; */
}

pre {
  font-family: "Aeonik", sans-serif;
  font-weight: 500;
  font-size: 16px;
  word-wrap: break-word;
  white-space: pre-wrap;
  line-height: 24px;
}

pre > p {
  margin: 0;
}

@media only screen and (max-width: 720px) {
  .App {
    width: 100%;
  }
}

/* Header */

header {
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  height: 100%;
  flex: 0 0 15%;
}

header > .sidebar-nav-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.indices-nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 100%;
  gap: 0.5rem
}

.indices-nav button {
  height: 80px;
  width: 100px;
}

@media only screen and (max-width: 720px) {
  header {
    display: none;
    width: 100%;
    left: unset;
  }
}

header > div {
  font-size: 14px;
}

h1 {
  font-size: 80px;
  line-height: 96px;
  font-weight: 800;
  margin: 0;
}

h2 {
  font-size: 60px;
  line-height: 84px;
  font-weight: 700;
  margin: 0;
}

h3 {
  font-size: 48px;
  line-height: 62px;
  font-weight: 700;
  margin: 0;
}

h4 {
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  margin: 0;
}

a {
  text-decoration: none;
  color: #7e7e7e;
  /* padding: 10px; */
}

.muted {
  color: #7e7e7e;
  margin: 0;
}

a:hover {
  cursor: pointer;
  color: #1dc1ae;
}

a.active {
  color: #1dc1ae;
}

body {
  background-color: #0f0f0f;
  color: #f1f1f1;
}

/* Main */

main {
  width: 100%;
  height: 100%;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #212121;
  position: relative;
  padding: 1rem;
}

.chat-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  width: 720px;
  max-height: calc(100vh - 388px);
  flex: 1 0 auto;
}

main > div > img {
  margin-top: 24px;
  width: 120px;
}

main > div > h1 {
  font-size: 65px;
  margin: 0;
  margin-top: 10px;
}

@media only screen and (max-width: 720px) {
  main > div > h1 {
    font-size: 36px;
  }
  main > div > h2 {
    font-size: 16px;
  }
  .chat-body {
    width: 100%;
  }
  header > div:last-child {
    text-align: center;
    flex: 1;
    padding: 0;
  }
}

@media only screen and (max-width: 400px) {
  main > div > h2 {
    font-size: 14px;
  }
}

.chat-body > .scrollable {
  width: 100%;
  text-align: left;
  font-size: 16px;
  overflow-y: auto;
  height: 100%;
  padding-right: 15px;
}

.scrollable {
  z-index: 1; /*Fix visibility in Mozilla browser*/
}

@media only screen and (max-width: 750px) {
  main > div > div {
    padding: 0 1rem;
  }
  .scrollable {
    width: 90%;
  }
}

main > div > div::-webkit-scrollbar {
  width: 2px;
  background-color: #ebebeb;
  border-radius: 2px;
}
main > div > div::-webkit-scrollbar-thumb {
  background-color: #1dc1ae;
  border-radius: 2px;
}
main > div > div::-webkit-scrollbar-track {
  background-color: #ebebeb;
  border-radius: 2px;
}
main > div > div {
  scrollbar-width: thin;
  scrollbar-color: #1dc1ae #ebebeb;
  border-radius: 2px;
}

main > div > div > .questions-answers {
  margin-bottom: 40px;
}
main > div > .white-fade {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 63%,
    rgba(255, 255, 255, 1) 100%
  );
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 5px);
  height: 100px;
}

@media only screen and (max-width: 750px) {
  main > div > .white-fade {
    width: calc(100% - 35px);
  }
}

.loading-dots {
  animation: dots 0.5s linear infinite;
  color: #1dc1ae;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(29, 193, 174, 0);
    text-shadow: 0.25em 0 0 rgba(29, 193, 174, 0),
      0.5em 0 0 rgba(29, 193, 174, 0);
  }
  40% {
    color: #1dc1ae;
    text-shadow: 0.25em 0 0 rgba(29, 193, 174, 0),
      0.5em 0 0 rgba(29, 193, 174, 0);
  }
  60% {
    color: rgba(29, 193, 174, 0);
    text-shadow: 0.25em 0 0 #1dc1ae, 0.5em 0 0 rgba(29, 193, 174, 0);
  }
  80%,
  100% {
    color: rgba(29, 193, 174, 0);
    text-shadow: 0.25em 0 0 rgba(29, 193, 174, 0), 0.5em 0 0 #1dc1ae;
  }
}

/* Footer */

footer {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #212121;
  padding: 1rem;
}

footer > span {
  padding: 0.5rem;
  font-size: 14px;
  color: #5b5b5b;
}

.chat-footer {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 1 10%;
}

.buttons {
  justify-content: center;
  margin-bottom: 1rem;
  top: 30px;
}

button {
  border: none;
  color: #f1f1f1;

  background-color: #139d8d;
  padding: 10px;
  margin: 0;
  font-size: 14px;
  z-index: 1;
}

button:hover {
  background-color: #1dc1ae;
  cursor: pointer;
}

button:disabled {
  background-color: #7e7e88;
  color: #d4d9e1;
  cursor: default;
}

button.button-important {
  background-color: red;
}

button.button-important:hover {
  background-color: #FF7F7F;
  cursor: pointer;
}

button.button-important:disabled {
  background-color: #7e7e88;
  color: #d4d9e1;
  cursor: default;
}

@media only screen and (max-width: 750px) {
  .buttons {
    top: -32px;
  }
  .chat-footer > div .send {
    right: 1rem;
  }
}

.chat-footer > span {
  margin: 1rem 0;
  font-size: 12px;
  color: #5b5b5b;
}

.chat-footer > div {
  display: flex;
  width: 100%;
  max-width: 720px;
}

.input-wrapper {
  position: relative;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  padding: 1px;
}

.model-selector {
  padding: 20px;
  border: none;
  background: #212121;
  color: #7e7e88;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  position: relative;
  font-size: 16px;
}

.model-selector:disabled {
  background: #3c3c3c;
  color: #9f9fa9;
  cursor: not-allowed;
}

.chat-footer > div > input {
  padding: 20px;
  width: 100%;
  max-width: 720px;
  border: none;
  background: #212121;
  color: #7e7e88;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  position: relative;
  font-size: 16px;
}

.chat-footer > div > input::placeholder {
  color: #9d9d9d;
}

.chat-footer > div > input[type="text"],
select {
  outline: none;
}

.chat-footer > div > input[type="text"] {
  outline: none;
  padding-right: 55px;
}

.chat-footer > div .send {
  position: absolute;
  top: 50%;
  left: unset;
  right: 0;
  width: 60px;
  padding: 18px;
  transform: translateY(-50%);
}

.chat-footer > div .send:hover {
  cursor: pointer;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul > li > .log-list-item {
  border-bottom: 1px solid #7e7e7e;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  color: inherit;
  position: relative;
}

ul > li > .log-list-item.active {
  background-color: #686868;
}

ul > li > .log-list-item:hover {
  background-color: #7e7e7e;
}

ul > li > .log-list-item > .log-list-remove-item {
  position: absolute;
  height: 100%;
  right: 0px;
  top: 0;
  width: 50px;
  text-align: center;
  padding: 14px;
}

ul > li > .log-list-item > .log-list-remove-item:hover {
  background-color: red;
}

.flex-title-with-action {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
}

.flex-title-with-action > a {
  font-size: 24px;
}
